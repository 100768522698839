// i18next-extract-mark-ns-start google-pay-for-woocommerce
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import woocommerce_googlepay from 'images/woocommerce_google-pay.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="google-pay-for-woocommerce"
        title={t('Google Pay WooCommerce | MONEI')}
        description={t(
          'Add Google Pay to your WooCommerce store to let your customers pay with one of the most popular digital wallets in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Get the Google Pay WooCommerce integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                Google Pay is used by hundreds of millions of people making it a vital payment
                option to add to your WooCommerce store. To accept this popular digital wallet,
                you’ll need a payment gateway that comes with a Google Pay WooCommerce integration.
              </Trans>
              <Trans parent="p">
                That’s where we come in. Use our payments platform to accept and manage the widest
                range of payment methods from one dashboard. Create your account now to configure
                GPay and more.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={woocommerce_googlepay}
              alt="Woocommerce google pay"
              title="Woocommerce google pay"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept GPay payments and many other WooCommerce payment methods</Trans>
              </SectionHeader>
              <Trans parent="p">
                Adding{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink> to
                your WooCommerce store is a good start, but it’s crucial to accept a wide range of{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>.
                Shoppers are looking for many payment options including{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>
                , more <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                , along with local methods of payment such as{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain.
              </Trans>
              <Trans parent="p">
                Accepting{' '}
                <BlogLink slug="alternative-payment-methods">more payment methods</BlogLink> helps
                you reach more people, improve the customer checkout experience, and increase
                conversions. That’s why we help you combine all major and alternative payment
                methods in a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Boost sales by approving more online payments</Trans>
              </SectionHeader>
              <Trans parent="p">
                Using <InternalPageLink slug="redsys-alternative">Redsys</InternalPageLink> alone as
                your payment processor can lead to unnecessary{' '}
                <BlogLink slug="online-payment-failure-message">online payment failure</BlogLink>{' '}
                messages. That’s why we built a{' '}
                <InternalPageLink slug="features/payments-orchestration">
                  payments orchestration
                </InternalPageLink>{' '}
                feature that sends online transactions to multiple payment processors. You’ll be
                able to create payment processing rules that fit your business needs. And if your
                default processor is experiencing downtime, we’ll send transactions to the
                best-performing provider.
              </Trans>
              <Trans parent="p">
                You’ll improve the checkout process, approve more payments, and increase your
                conversion rate while giving customers a more{' '}
                <BlogLink slug="frictionless-payments">frictionless payment</BlogLink> experience.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy the best WooCommerce payment gateway fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                Lower transaction fees mean you’ll have more money to reinvest in your business.
                That’s why we’re the only payment gateway to offer dynamic billing — as you sell
                more, your transaction fees will go down.
              </Trans>
              <Trans parent="p">
                Sign up for a flexible{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  WooCommerce payment gateway
                </InternalPageLink>{' '}
                that scales with your business —{' '}
                <InternalPageLink slug="pricing">view pricing here</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>
                Connect your WooCommerce store with MONEI to start accepting credit card payments
                (and more)
              </Trans>
            </SectionHeader>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/sections/360005144618-Google-Pay">
                  Configure Google Pay payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/woocommerce/">
                  WooCommerce plugin guide
                </AnchorLink>{' '}
                to connect MONEI to your online store
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "google-pay-for-woocommerce"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
